<template>
            <v-card
              outlined
            >
              <v-img
                asrc="@/assets/gchallenge.jpg"
                src="/img/ACC_S4_TOTAL.jpg"
                :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
                gradient="to top right, rgba(0,0,0,.6), rgba(220, 0, 0, 0.5)"
                class="align-end"
              >
                <v-card-title class="white--text pb-0 d-block">
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
                    style="word-break: normal !important"
                  >
                    BMW GT Challenge - Sezonul 4 - Overall
                  </p>
                  <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa Competizione</p>
                  <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">Pentru prima oară ne întrecem atât în curse sprint cât și în curse de anduranță. Cine va fi cel mai bun în clasamentele cumulate?</p>
                </v-card-title>
              </v-img>
              
              <v-card-text>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-list two-line class="py-0">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-calendar-month</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                              <span>
                                19 septembrie 2022 <v-icon small>mdi-arrow-right</v-icon> 13 martie 2023
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                      </v-list>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-car</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Mașini</v-list-item-title>
                            <span>
                              BMW M2 CS Racing &amp; BMW M4 GT4
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>
                        
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-trophy</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Parteneri</v-list-item-title>
                            <span>
                              BMW România, AQIRYS, Logitech România
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                        
                      </v-list>
                    </v-col>
                    
                    <v-col
                      cols="6"
                    >
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-flag-checkered</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                              <span>
                                8 etape de sprint, 5 etape de anduranță
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-podium</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Clasamente</v-list-item-title>
                              <span>
                                Clasamente pe piloți, echipaje, clase și cluburi
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              
              <v-card-actions>
                  <v-btn
                    id="series-menu-activator"
                    color="primary"
                  >
                    <v-icon left>mdi-clipboard-list</v-icon>Vezi serii
                  </v-btn>

                  <v-menu activator="#series-menu-activator">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        :value="index"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :to="`/season/${sprintSeriesId}?tab=standings`"
                        class="ma-2"
                      >
                        <v-list-item-title><v-icon left>mdi-clipboard-list</v-icon>Sprint</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :to="`/season/${enduSeriesId}?tab=standings`"
                        class="ma-2"
                      >
                        <v-list-item-title><v-icon left>mdi-clipboard-list</v-icon>Anduranță</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="green darken-1"
                    @click="goToOverallStandings()"
                  >
                    Clasament
                  </v-btn>
              </v-card-actions>
            </v-card>

</template>

<script>
export default {
    name:'BmwGtChallenge4',
    data: () => {
      return {
        sprintSeriesId: '6314ecbd4f2d680052188c18',
        enduSeriesId: '633de56ab4421100413fdc42',
        overallStandingsURL: 'https://www.simrace.ro/2022/09/20/clasamente-generale-rlr-bmw-gt-challenge-sezonul-4/'
      }
    },
    methods: {
      goToOverallStandings () {
        window.open(this.overallStandingsURL)
      }
    }
}
</script>
